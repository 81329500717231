@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
/*@import url('/stylesheets/okta-theme.css'); !*shows as error in IDE but resolves to public folder *!*/

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }

.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

.material-icons.md-light { color: rgba(255, 255, 255, 1); }

.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

.MuiGrid-root .MuiDropzonePreviewList-root {
  padding-top: 16px;
}

/*.MuiIconButton-colorInherit { color: rgba(0, 0, 0, 0.54);}*/

/*.MuiIcon-root { color: rgba(0, 0, 0, 0.54);}*/

/*.MuiInputBase-input {*/
/*  padding-top: 20px;*/
/*}*/